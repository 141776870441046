<!-- 此组件主要用来控制按钮的loading的效果的 -->
<template>
  <el-button v-bind="$attrs" :loading="loading" @click.stop="handleClick">
    <slot></slot>
  </el-button>
</template>

<script setup lang="ts">
import { ref } from 'vue'

// el-button控制loading
const loading = ref<boolean>(false)

const emits = defineEmits<{
  (e: 'cus-click', fn: () => void): void
}>()

// 点击处理button
const handleClick = async () => {
  loading.value = true
  emits('cus-click', () => {
    loading.value = false
  })
}
</script>

<script lang="ts">
export default {
  name: 'BaseButton',
}
</script>

<style scoped lang="scss"></style>
